.boxed{height: 100%;}
.txt-60{font-size:60px!important;}
.txt-8{font-size:8px!important;}

/*
 4.) Content MAIN - contenu principal
============================================================================================================================================================*/
/* Login
-------------------------------------------------------*/
.login .btn{line-height: 1.75rem;}
.login .form-group{margin-bottom: 0;}
.login .form-control{height: 2.75rem;}
/* Modal
-------------------------------------------------------*/
.modal-body { text-align: center;}
.modal-title{ text-transform: uppercase; padding-bottom: 25px;}
.modal-body .fa-exclamation-triangle{ color: #B8291C; font-size: x-large;}
.modal-authentification #btn-modal{ margin: auto; color: #001DFF;}
/* Form
-------------------------------------------------------*/
.form-control.is-valid{border: solid  1px #28a745;}
.form-control.is-invalid{ border: solid  1px #dc3545;}
/* Display carte
-------------------------------------------------------*/
.display-carte .card-body{ padding: 0.5rem 0.75rem; min-height: 125px;}
.display-carte li{padding:0.5rem;}
.display-carte img{height: 5rem;}
.display-carte .card-footer{background-color: #fff;}
.display-carte .carte-title{ vertical-align: middle;}
.display-carte .card-title {margin-top:0.5rem;}
/* sidebar
-------------------------------------------------------*/
#sidebar .navOpenClose button{
  height: 100%;
  width: 100%;
  padding: 5px 12px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background: none;
  border: 0;
}
#sidebar .navOpenClose {padding: 0;}
#sidebar .navOpenClose button {color: #757575;}
/* Operation recentes
-------------------------------------------------------*/
.operations-recentes{ height: 100%;}
.operations-recentes .card-title{
  background-color: #f6fbff;
  text-align: center;
  color: #0079c2;
  padding: 15px;
  margin-bottom: 0;
}
.operations-recentes .card-body {padding: 0;}
.operations-recentes .montant {text-align: end;color:  #003366; font-weight: bold;}
.operations-recentes .positif {color: green;}
.operations-recentes .btn {padding-left: 10px; padding-right: 10px;}
.operations-recentes .card-footer {background-color: white;border-top: 0;}
.operations-recentes td {border-bottom: 1px solid #dee2e6;}
.operations-recentes .table { margin-bottom: 0;}
/* Texte promotionel
-------------------------------------------------------*/
.alert-blp{ border: 0;}
.alert-blp:hover{ border: 1px solid #006CCF; }

/*.services .table .description{*/
/*  word-break: break-word;*/
/*}*/

#sidebar.active {
  display: none;
}

/*detailsoperations */
.details-operations-header .dropdown-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 1000;
  font-size: x-large;
  border: 0;
  right: 15px;
}
.details-operations-header .dropdown-toggle {width: 225px;text-align: left;color: gray;position: relative;}
.details-operations-header input {border: 0;}
.details-operations-header .btn-excel{border-left: #003366 1px solid;}
.titre-details-operations {font-size: 18px; text-transform: uppercase; color:#2383d5; font-weight: 400;}
.sous-titre-details-operations {font-size: 18px; color:#343a40; font-weight: 400;}

.releves .fleche {font-size: xx-large;color: #949c9e;background-color: transparent;border: 0; height: 100%}
.releves .fleche:focus {outline: 0;}
.releves .table td, .releves .table th{ padding: 0.25rem 0.75rem ;}

.border-top-blue{border-top: #003366 3px solid;}
.no-border-top{border-top: 0;}

.compte-points .fa-chevron-right { font-size: 70px; transform: scaleX(0.5); }
.compte-points .btn-miles { padding: 0.25rem 1.5rem;}
.compte-points .plane {height: 55px;}

.compte-points .card {flex-grow: 1;}
.compte-points .card-footer{ flex-grow: 1;}
.compte-points ul{ flex-grow: 4; display: flex;}
.compte-points li{ flex-grow: 1;}

.flexpay-info-presentation ul{
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 15px;
}
.flexpay-info-presentation .border-wide {border-width: 3px!important;}

.with-fit-content{width: fit-content}

.plan-pay-souscription .dropdown-toggle::after { display: inline-block; position: inherit; transform: none;}
.dropdown-button-objet-credit { padding: 3px 8px 3px 10px;}
.align-sub{ vertical-align: sub;}

.plan-pay-souscription .slider {
  background: #949c9e; /* Grey background */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  height: 3px;
}

/* Mouse-over effects */
.plan-pay-souscription .slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}



.blpnavbar .navbar-nav button, .blpnavbar.navbar-light .navbar-nav button {font-size: 14px; color:#006ecf;}
.navbar button:focus { outline: none;}
.blpnavbar .navbar-nav .nav-item.active button, .blpnavbar .navbar-nav button:hover {color:#65686b;}
#sidebar .nav-link {
  padding: 8px 5px 8px 10px;
}


.bg-grey-lighter {background-color:#F3F3F3 ;}

/* top header*/
 .fit-content{
   width: fit-content;
 }
 /**/

/** Changement bleue*/
.txt-blue-light {color: #0079c2;}
.bg-blue-light {background-color: #0079c2;}
.btn-primary {color: #fff; background-color: #0079c2; border-color: #0079c2;}
.top-head-lnk:hover {color: #0079c2; text-decoration: none;}
header .big-title {font-size:30px; color: #0079c2;}
.top-menu {color: #0079c2; padding-bottom: 2px;}
.dashboard .card-header.sideheader .titre1 {padding:10px 0; color: #0079c2; text-transform: uppercase; text-align:center; font-size: 18px;}
#sidebar ul a{font-size: 13px; color: #0079c2;}
#sidebar button{font-size: 13px; color: #0079c2;}
#sidebar button:hover {
  font-size: 13px;
  color: #65686b;
  text-decoration: none;
}
.offres .card-body h5 {color: #0079c2; font-size:14px;}
.services .card-header.sideheader .titre1 {padding:10px 0; color: #0079c2; text-transform: uppercase; text-align:center; font-size: 18px;}
.services .tab-infos .nav-link:hover,.services .tab-infos .nav-link:focus, .services .tab-infos .nav-link.active   {color: #0079c2;}
.scaleX{ transform: scale(2,1);}

.frise {
  height: fit-content;
  background-image: none;
  display: flex;
  align-items: flex-end;
}
.frise-gauche {
  max-width: 50%;
}
.frise-droite {
  max-width: 50%;
}
.frise-milieu {
  height: 8px;
  background-color: #0079c2 ;
  width: 100%;
  flex-grow: 1;
  margin: 0 5px;
}

pre {
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  color: #949ca0;
  white-space: pre-wrap;
  overflow: visible;
}

.ipay .dropdown-toggle::after {
  display: inline-block;
  position: inherit;
  right: 0;
  transform: none;
}

.dropdown-toggle::after {
  display: inline-block;
  position: inherit;
  transform: none;
}

.dropdown-toggle::after{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.convertisseur .exchange button:focus {
  outline: none;
}

.convertisseur .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0079c2;
}
.convertisseur .dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.convertisseur .dropdown-item button:focus {
  outline: none;
}
.convertisseur input::placeholder{
  font-size: 13px;
  color: darkgray;
}

.convertisseur .place-holder{
  font-size: 13px;
  color: darkgray;
}

.login .version {
  position: absolute;
  right: 0;
  bottom: 0;
}

.gestion-compte .deplafonement .dropdown-menu{
  height: auto;
  max-height: 150px;
  overflow: auto;

}

.e-releves-ico { background-image: url("src/assets/images/icones-gray/ico-releves-gray.svg");}
.active .e-releves-ico, .nav-link:hover .e-releves-ico  { background-image: url("src/assets/images/icones-blue/ico-releves-blue.svg");}

.deplafonement-ico { background-image: url("src/assets/images/icones-gray/ico-deplafonnement-gray.svg");}
.active .deplafonement-ico, .nav-link:hover .deplafonement-ico { background-image: url("src/assets/images/icones-blue/ico-deplafonnement-blue.svg");}

.freeze-ico  { background-image: url("src/assets/images/icones-gray/ico-freeze-gray.svg");}
.active .freeze-ico, .nav-link:hover .freeze-ico  { background-image: url("src/assets/images/icones-blue/ico-freeze-blue.svg");}

.opposition-ico  { background-image: url("src/assets/images/icones-gray/ico-opposition-gray.svg");}
.active .opposition-ico, .nav-link:hover .opposition-ico  { background-image: url("src/assets/images/icones-blue/ico-opposition-blue.svg");}

.prelevement-ico  { background-image: url("src/assets/images/icones-gray/ico-prelevement-gray.svg");}
.active .prelevement-ico, .nav-link:hover .prelevement-ico  { background-image: url("src/assets/images/icones-blue/ico-prelevement-blue.svg");}

.alerte-ico  { background-image: url("src/assets/images/icones-gray/ico-alerte-gray.svg");}
.active .alerte-ico, .nav-link:hover .alerte-ico  { background-image: url("src/assets/images/icones-blue/ico-alerte-blue.svg");}

.password-ico  { background-image: url("src/assets/images/icones-gray/ico-password-gray.svg");}
.active .password-ico, .nav-link:hover .password-ico  { background-image: url("src/assets/images/icones-blue/ico-password-blue.svg");}

.cookies-ico  { background-image: url("src/assets/images/icones-gray/ico-cookies-gray.svg");}
.active .cookies-ico, .nav-link:hover .cookies-ico  { background-image: url("src/assets/images/icones-blue/ico-cookies-blue.svg");}

.popup-confirmer-delay-ico { background-image: url("src/assets/images/ico-popup-confirmer-delay.svg")}
.popup-confirmer-ico { background-image: url("src/assets/images/ico-popup-confirmer.svg")}
.popup-erreur-ico { background-image: url("src/assets/images/ico-popup-erreur.svg")}

.icone {
  height: 55px;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-gestion-compte .nav-link {
  width: 110px;
  margin: auto;
}

.faq-ico {background-image: url("../images/icones-gray/ico-faq-gray.svg");}
.active .faq-ico, .nav-link:hover .faq-ico  { background-image: url("src/assets/images/icones-blue/ico-faq-blue.svg");}

.nous-contacter-ico {background-image: url("../images/icones-gray/ico-nous-contacter-gray.svg");}
.active .nous-contacter-ico, .nav-link:hover .nous-contacter-ico  { background-image: url("src/assets/images/icones-blue/ico-nous-contacter-blue.svg");}

.info-legale-ico {background-image: url("../images/icones-gray/ico-info-legale-gray.svg");}
.active .info-legale-ico, .nav-link:hover .info-legale-ico  { background-image: url("src/assets/images/icones-blue/ico-info-legale-blue.svg");}



.carte-perdue-ico{
  background-image: url("../images/icones-dark-blue/ico-contact-carte-perdue.svg");
}
.assistance-ico{
  background-image: url("../images/icones-dark-blue/ico-contact-assistance.svg");
}
.europ-assistance-ico{
  background-image: url("../images/icones-dark-blue/ico-contact-europ-assistance.svg");
}
.service-client-ico{
  background-image: url("../images/icones-dark-blue/ico-contact-services-client.svg");
}


.card-text-intro {
  color: #343a40;
  font-size: 16px;
}


select.form-control.is-valid, select.form-control.is-invalid {
  background-position: right calc(.375em + .8rem) center;
}

.w-90{ width: 90%}
.btn-none{ border: none; background: none;padding: 0;}
.btn-none:focus{ border: none; outline: none; }

.bg-grey-lighter{
  background-color: #f9f9f9;
}

.info-legale-ico-dark-blue{
  background-image: url("../images/icones-dark-blue/ico-info-legale.svg");
}

.rgpd-ico{
  background-image: url("../images/icones-dark-blue/ico-rgpd.svg");
}

.test .modal-content{
  border-radius: 15px;
}


@media (max-width: 1249px) {
}
@media (max-width: 1199px) {

}
@media (max-width: 1024px) {

}
@media (max-width: 991px) {

}
@media (max-width: 767px) {
  #sidebar .nav-link {
    padding: 6px 3px 8px 10px;
  }
}
