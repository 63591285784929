/* CSS Document pour le site BLP2
Développement : PITI
CSS : DIGICREA

Table Of Contents

 1.) Typography
 2.) Default Styles
 3.) Header - Main
 4.) Content - Main
 5.) Footer - Main
 6.) Responsive features
====================================================*/
/*
 1.) Typography
============================================================================================================================================================*/
@import url("all.min.css") all;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

body {font-family: 'Roboto', sans-serif;}
body.site {background-color: #f9f9f9; font-size: 14px; color:#949ca0;}
/*
 2.) Default Styles
============================================================================================================================================================*/
.container-wrap {width:100%;}

.lnh-24 {line-height: 24px;}
.lnh-50 {line-height: 50px;}
.lnh-78 {line-height: 78px;}
.row-border {border-bottom: 1px solid rgba(0,0,0,.125);padding: .75rem 1.25rem;}
.row-border-2 {border-bottom: 1px solid rgba(0,0,0,.125);border-top: 1px solid rgba(0,0,0,.125);padding: .75rem 1.25rem;}
.row-border-dark {border-bottom: 1px solid rgba(0,0,0,.5);padding: .75rem 1.25rem;}
.row-border-dark-2 {border-bottom: 1px solid rgba(0,0,0,.5);border-top: 1px solid rgba(0,0,0,.5);padding: .75rem 1.25rem;}
.rounded-xlg {border-radius: .8rem !important;}
.row.custom-gutter > [class*='col-'] {padding-right:10px;padding-left:10px; }
.row.custom-gutter > [class*="col-"]:first-child { padding-left:15px;}
.row.custom-gutter > [class*="col-"]:last-child {  padding-right:15px;}

/* separator
-------------------------------------------------------*/
.separator50 {height:50px;}
.separator40 {height:40px;}
.separator35 {height:35px;}
.separator30 {height:30px;}
.separator20 {height:20px;}

/* Text size
-------------------------------------------------------*/
.txt-50{font-size:50px!important;}
.txt-40{font-size:40px!important;}
.txt-35{font-size:35px!important;}
.txt-32{font-size:32px!important;}
.txt-30{font-size:30px!important;}
.txt-24{font-size:24px!important;}
.txt-20{font-size:20px!important;}
.txt-19{font-size:19px!important;}
.txt-18{font-size:18px!important;}
.txt-17{font-size:17px!important;}
.txt-16{font-size:16px!important;}
.txt-15{font-size:15px!important;}
.txt-14{font-size:14px!important;}
.txt-13{font-size:13px!important;}
.txt-12{font-size:12px!important;}
.txt-10{font-size:10px!important;}

/* Text colors
-------------------------------------------------------*/
.txt-blue-deep {color:#003366;}
.txt-blue-light {color: #0079c2;}
.txt-grey-deep {color:#65686b;}
.txt-grey-light {color:#949c9e;}
.txt-green {color:#43a34c;}
/* Background color
-------------------------------------------------------*/
.bg-blue-deep {background-color:#003366;}
.bg-blue-light {background-color: #0079c2;}
.bg-blue-light-light {background-color: #66A9E2;}
.bg-grey-deep {background-color:#65686b;}
.bg-grey-light {background-color:#949c9e;}
.bg-green {background-color:#43a34c;}
.bg-b-light {background-color:#f1f9ff;}

/* Links
-------------------------------------------------------*/
.lnk-utile {display:block; color:#003366; }
.lnk-utile::before {content: "\a0 ›";  font-size:20px; padding-right: 10px;}
.lnk-utile:hover {color:#4e4e52; text-decoration: none;}
/* Headings: P, H1, H2, H3, H4, H5, H6
-------------------------------------------------------*/
.titre-h2-carte {font-size: 30px; color:#000; margin: 30px 0; font-weight: 300;}


/* Buttons
-------------------------------------------------------*/
.btn-primary {color: #fff; background-color: #0079c2; border-color: #0079c2;}
.btn-white {color: #003366; background-color: #fff; border-color: #fff;}
.btn-dark-blue {color: #fff; background-color: #003366; border-color: #003366;}
.btn-green {color: #fff; background-color: #43a34c; border-color: #43a34c;}
.btn-blp-grey {color: #757575; background-color: #d6d6de; border-color: #d6d6de;}
.btn-contour { border: 1px solid #e4e2e2; border-radius: 5px; padding:10px 20px;}
.btn-contour-grey{ border: 1px solid #65686b;background-color:#efefef; border-radius: 5px;}
.btn-large { padding: 0.75rem 1.5rem}
.btn-bar { padding: 0.3rem 1rem}
.btn-grey-gradiant { background: rgb(255,255,255);background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(233,234,235,1) 65%, rgba(156,158,165,1) 100%); }

.btn-primary:hover {color: #fff; background-color: #00559f; border-color: #00559f;}
.btn-white:hover {color: #003366; background-color: #dcdcdc; border-color: #dcdcdc;}
.btn-dark-blue:hover {color: #fff; background-color: #145290; border-color: #145290;}
.btn-green:hover {color: #fff; background-color: #177520; border-color: #177520;}
.btn-blp-grey:hover {color: #757575; background-color: #a4a4b0; border-color: #d6d6de;}
.btn-contour:hover { border: 1px solid #e4e2e2; border-radius: 5px; padding:10px 20px;}
.btn-contour-grey:hover{ border: 1px solid #65686b;background-color:#4B4B4B; border-radius: 5px; color:#fff;}
.btn-grey-gradiant:hover { background: rgb(255,255,255);background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(38,145,200,1) 100%); }

.btn-primary.disabled { color: #fff; background-color: #66A9E2; border-color: #66A9E2;}

/*
 3.) Header - Main
============================================================================================================================================================*/
.top-head-lnk {color:#585c5e;}
.top-head-lnk:hover {color: #0079c2; text-decoration: none;}
.top-head-lnk .fas {font-size:20px; vertical-align: middle; margin-right: 0.3rem;}

.zone-menu{background-color: #fff;min-height: 78px;border-top: 1px solid #D4D4D4;border-bottom: 1px solid #D4D4D4;-webkit-box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);
-moz-box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);
}
.zone-menu-principal {background-color: #fff;min-height: 36px;border-top: 1px solid #D4D4D4;border-bottom: 1px solid #D4D4D4;-webkit-box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);
-moz-box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);box-shadow: 0px 1px 2px 0px rgba(122,121,122,0.2);
}
header .big-title {font-size:30px; color: #0079c2;}
header h1.big-title {margin-bottom:0; font-weight: 400;}
.top-menu {color: #0079c2; padding-bottom: 2px;}
.top-menu:hover, .top-menu:active, .top-menu.active {color:#4e4e52; text-decoration: none; border-bottom: 2px solid #0773d0;}



/* Navigation principale - Main nav  ----------------*/
.card-menu {margin-top:8px;}
.name-menu { padding-left: 27px;}
.navbar.blpnavbar {padding:0;}
.blpnavbar .navbar-nav a, .blpnavbar.navbar-light .navbar-nav a {font-size: 14px; color:#006ecf;}
.blpnavbar .navbar-nav .nav-item.active a, .blpnavbar .navbar-nav a:hover {color:#65686b;}
.blpnavbar .navbar-toggler-icon { width: 1em;height: 1em;}
.blpnavbar.navbar-light .navbar-toggler {border-color: rgba(0,0,0,0) !important;}
/*
 4.) Content MAIN - contenu principal
============================================================================================================================================================*/
/* Login
-------------------------------------------------------*/
.login .input-group-text{background-color: #fff; border-right: none;}
.login .form-control{border-left: none;}
.login .fas{color:#34567e}
.login input::placeholder{color:#34567e; font-size: 15px;}
.login .btn {padding: .25rem 1.2rem;}

/* Main
-------------------------------------------------------*/
.boxed {background-color: #fff; border: 1px solid #e4e2e2; border-radius: 5px; padding:15px;}
.main-xpf {text-transform:uppercase; font-size:16px;}
.carte-title{ font-size:15px; font-weight: 500; color:#65686b;}
.carte-montant {font-size:18px;}
.carte-montant span {font-size:16px;}

.titre-card-dash {font-size: 18px; text-transform: uppercase; color:#2383d5; font-weight: 400;padding-bottom: 10px;}
.titre-card-dash-small {font-size: 18px; color:#2383d5; font-weight: 400; padding-bottom: 10px;}
.titre-card-sstitre {font-size: 12px; color:#2383d5; font-weight: 300; padding-bottom: 10px;}
.titre-solde-grey {font-size: 34px; font-weight: 300; color:#65686b; padding-bottom: 10px;}
.titre-solde-grey span {font-size: 24px; font-weight: 200;}

.dashboard .card-body {padding: 0.5rem 0;}
.dashboard .card-header.sideheader  {padding:0;}
.dashboard .card-header.sideheader .titre1 {padding:10px 0; color: #0079c2; text-transform: uppercase; text-align:center; font-size: 18px;}
.header-blue { background-color:#f6fbff; font-size:18px; color:#2383d5; text-transform: uppercase;}
.header-blue-txt-darkblue { background-color:#f6fbff; font-size:18px; color:#003366; font-size: 24px; font-weight: 300;}
.header-blue-dark { background-color:#003366; font-size:18px; color:#ffffff; text-transform: uppercase;}
.header-white { background-color:#fff; font-size:18px; color:#2383d5; text-transform: uppercase;}
.header-grey { background-color:#d6d6d6; font-size:18px; color:#4e4e52; text-transform: uppercase;}

/* Alert
-------------------------------------------------------*/
.alert-blp {background-color: #fff;border: 1px solid #006CCF; padding:20px; color: #b5313d; font-weight:500; }

/* sidebar
-------------------------------------------------------*/

#content {width: 100%;padding: 0;-webkit-transition: all 0.3s;-o-transition: all 0.3s;transition: all 0.3s; }
#sidebar {min-width: 25%;max-width:25%;-webkit-transition: all 0.3s;  -o-transition: all 0.3s;  transition: all 0.3s;  position: relative; z-index: 1;}
#sidebar.active { margin-left: -270px; opacity:0; }
#sidebar ul.components {padding: 0; margin-bottom:0; }
#sidebar ul > li{list-style: none;}
#sidebar ul > li.last{padding-bottom:0;}
#sidebar ul a{font-size: 13px; color: #0079c2;}
#sidebar .nav-link {padding: 8px 5px 8px 10px;}
#sidebar .nav-link-show{color:#65686b;}
#sidebar ul.sidenav{width: 90%}
#sidebar .sidenav li {padding: 0}
#sidecontrol.hide {display:none}
#sidebar .navOpenClose a {color:#757575;}
#sidebar .navOpenClose {height: 100%; background-color:#d6d6de;position: absolute; right: 0; padding:5px 12px;}
#sidebar .btnOpenClose {height: 100%; vertical-align: middle; display: inline-block;}
#sidebar .btnOpenClose .fas{position: absolute; top: 50%;  -webkit-transform: translateY(-50%);  -ms-transform: translateY(-50%); transform: translateY(-50%); right: 5px;}
#sidebar ul a.active, #sidebar ul a:hover {font-size: 13px; color:#65686b;text-decoration: none;}
a[data-toggle="collapse"] {  position: relative; }
.dropdown-toggle::after { display: block; position: absolute; top: 50%; right: 0;  -webkit-transform: translateY(-50%);  -ms-transform: translateY(-50%); transform: translateY(-50%); }

.box-menu-blp {background-color: #fff;	border-radius: 0.25rem;	border: 1px solid #D6D6DE; min-width: 80%;position: relative;}
.custom-menu .btn {border-radius: 5rem; padding:6px;font-size: 0.9rem;line-height: 0.1rem; }
.custom-menu  {position:relative; z-index: 100}

ul.main-menu li,
ul.site-options li {  margin: 10px 0;}

ul.main-menu li ul {   display: none;  position: relative;  max-height: 1000px;  /*fallback for FireFox */  height: 100%;}
ul.main-menu li ul.open {  display: block;}

ul.main-menu li i {  transition: all 0.5s;  position: absolute;  right: 10px;}
ul.nav-second-level { padding: 10px 0; background-color: #f4f4f4;}

/* Offres
-------------------------------------------------------*/
.offres .blpoverflow {max-height: 800px;}
.offres .card-body {padding:0 !important;}
.offres .card-body h3 {color:#003366; font-size:16px;}
.offres .card-body h4 {color:#8d8c8d; font-size:13px;}
.offres .card-body h5 {color: #0079c2; font-size:14px;}

/* Catégorisation
-------------------------------------------------------*/
.categorisation .card-body {padding:0 !important;}
.categorisation .row-border {padding:0 !important; margin:0 !important;}
.categorisation select {background-color: transparent;  border: none;  display: inline-block;font: inherit;line-height: 1.2em; padding:0;margin: 0;-webkit-box-sizing: border-box;  -moz-box-sizing: border-box;  box-sizing: border-box;  -webkit-appearance: none;  -moz-appearance: none;}
.categorisation label {position:relative; margin: 0;}
.categorisation .select::after { font-size:18px;    content:"\f107";  font-family: "Font Awesome 5 Free"; color:gray;right:8px; top:-2px;padding:0;position:absolute;pointer-events:none;   }
.categorisation label:before {content:'';right:4px; top:-2px; width:23px; height:18px;background: transparent;position:absolute;pointer-events:none;display:block;}

/* Services
-------------------------------------------------------*/
.services .card-body {padding: 0;}
.services .card-header.sideheader  {padding:0;}
.services .card-header.sideheader .titre1 {padding:10px 0; color: #0079c2; text-transform: uppercase; text-align:center; font-size: 18px;}

.services .table tfoot tr{ margin-top : 25px;}
.services .table thead th {vertical-align: middle !important; font-size:14px; color:#003366; font-weight: 400;}
.services .table th {padding: 0.3rem 1rem;}
.services .table td {padding: 0.75rem 1rem;}
.services .table .date {}
.services .table .description {}
.services .table .montant {text-align: right;}
.services .table .montant1 {text-align: right;}
.services .table .montant2 {text-align: right;}
.services .table .montant3 {text-align: right;}
.services .table .footertable td {font-size:17px;color:#003366;font-weight:500;  }
.services .table .footertable td.montant {font-weight: 600;}
.services .table .footertable td.montant1 {font-weight: 600;}
.services .table .footertable td.montant2 {font-weight: 600;}
.services .table .footertable td.montant3 {font-weight: 600;}
.services .tab-infos .nav-link  {color:#949ca0;}
.services .tab-infos .nav-link:hover,.services .tab-infos .nav-link:focus, .services .tab-infos .nav-link.active   {color: #0079c2;}

.services .header-box {padding : 35px 30px;}

.services input[type=checkbox] { display:none; }
.services input[type=checkbox] + label:before {  font-family:"Font Awesome 5 Free";display: inline-block;}
.services input[type=checkbox] + label:before { content: "\f0c8"; font-size:16px;}
.services input[type=checkbox]:checked + label:before { content: "\f14a";font-weight: 900; font-size:16px;}

 /* Relevés
-------------------------------------------------------*/
.releves .filter select {background-color: #fff;  border: none;  display: inline-block;font: inherit;line-height: 1.5em; padding:7px 30px 6px 6px;margin: 0;-webkit-box-sizing: border-box;  -moz-box-sizing: border-box;  box-sizing: border-box;  -webkit-appearance: none;  -moz-appearance: none; color:#65686b;}
.releves .filter label {position:relative; margin: 0;}
.releves .filter .select::after { font-size:18px;    content:"\f107";  font-family: "Font Awesome 5 Free"; color:gray;right:8px; top:3px;padding:0;position:absolute;pointer-events:none;   }
.releves .filter label:before {content:'';right:4px; top:-2px; width:23px; height:18px;background: transparent;position:absolute;pointer-events:none;display:block;}
.releves .filter .btn-outline-secondary {color: #65686b;    border: 1px solid #ced4da; padding:2px 15px 2px 5px;}
.releves .filter .form-control {font-size:14px; color:#65686b;}
.releves .row-border {border-bottom: 1px solid rgba(0,0,0,.125);padding: .75rem 0;}
.releves .operations .card-body {padding : 0;}
/*
 5.) Footer - Main
============================================================================================================================================================*/
.frise {height: 30px;margin: 20px 0;background-image: url(../images/frise-Amex.jpg);background-repeat: repeat-x;}
footer a {font-size:10px; color:#337ab7;}
footer a:hover { color:#65686b;}
/*
 6.) Responsive features
============================================================================================================================================================*/

@media (max-width: 1249px) {
}
@media (max-width: 1199px) {
	.name-menu {padding-left: 40px;}
	#sidebar.active { margin-left: -25%; }
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
/*#sidebarCollapse span {
    display: none; }
    #sidebar { margin-left: 0; }
     #sidebar.active {margin-left: -270px; }*/
  .name-menu {padding-left: 15px;}
}
@media (max-width: 767px) {
   .wrapper.block{display:block !important;}
	.wrapper.block #sidebar {margin-bottom:20px; max-width: 100%;}
}

